import React from 'react';

const HasMigraine = ({ onAnswer }) => {
    return (
        <div className="card">
            <h2 className="card-title">Have you experienced migraine since the last time?</h2>
            <div className="button-group">
                <button
                    onClick={() => onAnswer(true)}
                    className="button button-primary full-width"
                >
                    Yes
                </button>
                <button
                    onClick={() => onAnswer(false)}
                    className="button full-width"
                >
                    No
                </button>
            </div>
        </div>
    );
};

export default HasMigraine;