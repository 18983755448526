import React from 'react';

const intensityOptions = [
    { value: 'None', label: 'None, Headache free day' },
    { value: 'Mild', label: 'Mild, can function' },
    { value: 'Moderate', label: 'Moderate, slowed' },
    { value: 'Severe', label: 'Severe, cannot function' },
    { value: 'Extreme', label: 'Extreme, Miss work or personal activity' }
];

function PainIntensitySelector({ data, onNext, onBack }) {
    const handleSelectIntensity = (intensity) => {
        onNext({ intensity });
    };

    return (
        <div className="card">
            <h2 className="card-title">How intense is your pain?</h2>
            <div className="button-group">
                {intensityOptions.map((option) => (
                    <button
                        key={option.value}
                        className={`button button ${data.intensity === option.value ? 'selected' : ''}`}
                        onClick={() => handleSelectIntensity(option.value)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
}

export default PainIntensitySelector;