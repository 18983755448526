import React from 'react';

const painkillersEffectivnessOptions = [
    { value: 'Success' },
    { value: 'Partial Benefit' },
    { value: 'Failure' },
    { value: 'Recurrence' },
    { value: 'Side Effects' },
];

function PainkillersEffectivnessSelector({ data, onNext, onBack }) {
    const handleSelectEffectivness = (painkillersEffectivness) => {
        onNext({ painkillersEffectivness });
    };

    return (
        <div className="card">
            <h2 className="card-title">How were the painkillers effective?</h2>
            <div className="button-group">
                {painkillersEffectivnessOptions.map((option) => (
                    <button
                        key={option.value}
                        className={`button button ${data.painkillersEffectivness === option.value ? 'selected' : ''}`}
                        onClick={() => handleSelectEffectivness(option.value)}
                    >
                        {option.value}
                    </button>
                ))}
            </div>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
}

export default PainkillersEffectivnessSelector;