import React from 'react';

const painOnsetOptions = [
    { value: 'Slow' },
    { value: 'Average' },
    { value: 'Rapid' },
    { value: 'Sudden' },
];

function PainOnsetSelector({ data, onNext, onBack }) {
    const handleSelectPainOnset = (painOnset) => {
        onNext({ painOnset });
    };

    return (
        <div className="card">
            <h2 className="card-title">What is the onset on your pain?</h2>
            <div className="button-group">
                {painOnsetOptions.map((option) => (
                    <button
                        key={option.value}
                        className={`button button ${data.painOnset === option.value ? 'selected' : ''}`}
                        onClick={() => handleSelectPainOnset(option.value)}
                    >
                        {option.value}
                    </button>
                ))}
            </div>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
}

export default PainOnsetSelector;