import React from 'react';

const ConfirmSubmit = ({ painData, onSubmit, onBack }) => {
    return (
        <div className="card">
            <h2 className="card-title">Confirm and Submit</h2>
            {painData.hasMigraine ? (
                <>
                    <p>Pain Intensity: {painData.painData.intensity}</p>
                    <p>Pain Location: {painData.painData.location}</p>
                    <p>Pain Type: {painData.painData.painType}</p>
                    <p>Pain Onset: {painData.painData.painOnset}</p>
                    <p>Painkiller: {painData.painData.painkiller}</p>
                    <p>Painkillers Effectivness: {painData.painData.painkillersEffectivness}</p>
                </>
            ) : (
                <p>No migraine reported for this entry.</p>
            )}
            <button onClick={onSubmit} className="button button-primary full-width">
                Submit
            </button>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
};

export default ConfirmSubmit;