import React, { useState } from 'react';

const PainLocationInput = ({ data, onNext, onBack }) => {
    const [location, setLocation] = useState(data.location || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        onNext({ location });
    };

    return (
        <div className="card">
            <h2 className="card-title">Where is your pain located?</h2>
            <form onSubmit={handleSubmit}>
                <textarea
                    className="input"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Describe the location of your pain (e.g., left temple, behind eyes)"
                    rows={4}
                />
                <button type="submit" className="button button-primary full-width">Next</button>
            </form>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
};

export default PainLocationInput;