import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import config from '../../config';
import { UserContext } from '../../UserContext';

const initialPainTypes = ['Throbbing', 'Stabbing', 'Pressure', 'Burning'];

const PainTypeSelector = ({ data, onNext, onBack }) => {
    const [painType, setPainType] = useState(data.painType || '');
    const [customTypes, setCustomTypes] = useState([]);
    const [isCustom, setIsCustom] = useState(false);
    const userId = useContext(UserContext);

    useEffect(() => {
        if (userId) {
            fetchUserPainTypes();
        }
    }, [userId]);

    const fetchUserPainTypes = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/api/pain-types/${userId}`);
            setCustomTypes(response.data);
        } catch (error) {
            console.error('Error fetching user pain types:', error);
        }
    };

    const handleTypeSelection = (type) => {
        setPainType(type);
        setIsCustom(false);
    };

    const handleCustomTypeChange = (e) => {
        setPainType(e.target.value);
        setIsCustom(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isCustom && painType && !customTypes.includes(painType) && userId) {
            try {
                await axios.post(`${config.apiUrl}/api/pain-types/${userId}`, { painType });
                setCustomTypes([painType, ...customTypes]);
            } catch (error) {
                console.error('Error saving custom pain type:', error);
            }
        }
        onNext({ painType });
    };

    return (
        <div className="card">
            <h2 className="card-title">What type of pain are you experiencing?</h2>
            <form onSubmit={handleSubmit}>
                <div className="grid">
                    {[...customTypes, ...initialPainTypes].map((type, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`grid-item ${painType === type ? 'selected' : ''}`}
                            onClick={() => handleTypeSelection(type)}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                <input
                    type="text"
                    className="input"
                    value={isCustom ? painType : ''}
                    onChange={handleCustomTypeChange}
                    placeholder="Or describe your own pain type"
                />
                <button type="submit" className="button button-primary full-width">Next</button>
            </form>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
};

export default PainTypeSelector;