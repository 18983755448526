import React, { useState } from 'react';

const PainkillerInput = ({ data, onNext, onBack }) => {
    const [painkiller, setPainkiller] = useState(data.painkiller || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        onNext({ painkiller });
    };

    return (
        <div className="card">
            <h2 className="card-title">What painkiller have you used?</h2>
            <form onSubmit={handleSubmit}>
                <textarea
                    className="input"
                    value={painkiller}
                    onChange={(e) => setPainkiller(e.target.value)}
                    placeholder="Painkiller name / dosage (e.g. Ibalgin/20mg)"
                    rows={4}
                />
                <button type="submit" className="button button-primary full-width">Next</button>
            </form>
            <button onClick={onBack} className="button full-width" style={{ marginTop: '10px' }}>
                Back
            </button>
        </div>
    );
};

export default PainkillerInput;