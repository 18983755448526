const config = {
    development: {
      apiUrl: 'https://easemymigraine.com',
    },
    production: {
      apiUrl: 'https://api.yourdomain.com', // Replace with your actual production API URL
    },
  };
  
  const environment = process.env.REACT_APP_ENV || 'development';
  
  export default config[environment];